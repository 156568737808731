import React from 'React';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPartnerLogo {
        edges {
          node {
            partnerName
            altText
            logo {
              gatsbyImageData(
                formats: [AUTO, WEBP],
                cropFocus: CENTER
              )
            }
          }
        }
      }
    }
  `)
  return (
    <div className="w-full text-center flex justify-center p-4">
      <div className="w-full max-width">
        <h2 className="text-2xl font-bold mt-8 mb-6">We've Been Featured On:</h2>
        <div className="flex justify-center max-width w-full flex-wrap">
          {
            data.allContentfulPartnerLogo.edges.map((logo, index) => {
              return (
                <GatsbyImage 
                  key={`partnerLogo-${index}`}
                  image={logo.node.logo.gatsbyImageData}
                  className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 h-32 p-4"
                  imgStyle={{ objectFit: 'contain', padding: '16px 24px 16px 24px' }}
                  alt={logo.node.altText}
                />
              )
            })
          }
      </div>
    </div>
  </div>
  )
}

export default Partners