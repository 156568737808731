import React from "react"
import Button from "../button"

const CtaBanner = () => (
  <div className="w-full text-center bg-blue-600 pb-12">
    <h2 className="text-2xl font-bold text-white pt-12 mb-8">
      Get better faster!
    </h2>
    <Button light linkTo="/contact-us/">
      Contact Us
    </Button>
  </div>
)

export default CtaBanner
