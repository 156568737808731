import React from "react"
import MailchimpForm from "../mailchimp-form"

const MailCta = () => {
  return (
    <div>
      <MailchimpForm></MailchimpForm>
    </div>
  )
}

export default MailCta
