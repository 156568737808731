import React from "react";
import { FaStar } from "react-icons/fa";
import Spacer from "../spacer";
import Carousel from "../carousel";
import { useStaticQuery, graphql } from "gatsby";

const nameLastTruncated = fullName => {
  const spaceInd = fullName.indexOf(" ");
  return fullName.substring(0, spaceInd + 2) + ". - ";
};

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulUserReviews(filter: { featured: { eq: true } }) {
        edges {
          node {
            stars
            reviewText {
              reviewText
            }
            reviewer
            featured
          }
        }
      }
    }
  `);

  const fiveStars = (
    <div className="flex">
      <FaStar style={{ fill: "#f9b403" }} />
      <FaStar style={{ fill: "#f9b403" }} />
      <FaStar style={{ fill: "#f9b403" }} />
      <FaStar style={{ fill: "#f9b403" }} />
      <FaStar style={{ fill: "#f9b403" }} />
    </div>
  );
  return (
    <div className="flex justify-center text-center px-2 bg-gray-100">
      <div className="max-width overflow-hidden flex-col items-center mb-6">
        <h2 className="px-2 mt-12 text-2xl font-bold tracking-wide leading-normal">
          Testimonials
        </h2>
        <Spacer />
        <iframe
          className="w-full embed-vid-player"
          src="https://www.youtube.com/embed/gPCsayrhfAQ?rel=0"
          title="No More Joint Pain, No More Neuropathy. Soft Tissue Treatment Video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <p className="text-gray-900 text-lg leading-normal mb-2">
          The results are in — it works. Hear what past patients have to say.
        </p>

        <div className="w-full lg:flex justify-center">
          <Carousel
            spotlightBreakpoints={{ 0: 1, 768: 2 }}
            cycleTime={15}
            classes="p-2"
          >
            {data.allContentfulUserReviews.edges.map((review, ind) => (
              <div
                className="rounded overflow-hidden h-full shadow-lg w-full text-left bg-white"
                key={"review-" + ind}
              >
                <div
                  className={`w-full pt-1 pb-1 bg-blue-${4 + (ind % 3)}00`}
                ></div>
                <div className="px-6 pb-4 flex flex-col h-full justify-between">
                  <p className="text-gray-800 text-base mt-3 pr-4">
                    {review.node.reviewText.reviewText}
                  </p>
                  <div className="flex">
                    <p className="mr-1 text-gray-900 font-semibold text-lg">
                      {nameLastTruncated(review.node.reviewer)}
                    </p>
                    {fiveStars}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
